import React from 'react';
import Construction from '../Construction';


const Work = () => {
    return (
        <div>
            <Construction />
            {/* <div className="header">Experience</div>
            <Job company="Amazon" title="Software Engineer" tenure="2023 - Present" description="Worked on various projects. Worked on various projects. Worked on various projects. Worked on various projects. Worked on various projects. Worked on various projects." />
            <Job company="Amazon" title="Software Development Engineer Intern" tenure="May 2022 - August 2022" description="Worked on various projects. Worked on various projects. Worked on various projects. Worked on various projects. Worked on various projects. Worked on various projects." />
            <Job company="CaseWare" title="Test Developer Intern" tenure="January 2021 - August 2021" description="Worked on various projects. Worked on various projects. Worked on various projects. Worked on various projects. Worked on various projects. Worked on various projects." />
            <Job company="BZAM" title="Software Developer" tenure="June 2021 - July 2021" description="Worked on various projects. Worked on various projects. Worked on various projects. Worked on various projects. Worked on various projects. Worked on various projects." />
            <div className="header">Projects</div>
            <Job company="Vision" description="i built stuff lolz" />
            <div className="header">Education</div> */}
        </div>
    );
};  
export default Work