import './Construction.css';

export default function Construction() {

    return (
        <div>
          <p className='Construction'>🚧 under construction 🚧</p>
          <div>check back soon!</div>
        </div>
      );
}